// External libraries
import * as React from "react";
import { MapContextType } from "./typings";

export const MapContext = React.createContext<MapContextType>({
  sendUserToSRP: () => null,
  shouldOpenMapInSRP: false,
  toggleShowFullscreenMap: () => null,
});

export const useMapContext = () => React.useContext<MapContextType>(MapContext);
