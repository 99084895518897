import * as React from "react";
import { ModuleMapProps } from "./typings";
import { DynamicMaps } from "./views/DynamicMaps";
import { withStores } from "stores";
import { EGMaps } from "./EGMaps";
import { observer } from "mobx-react";

export const MapsViews = withStores(
  "analytics",
  "hotels",
  "staticMap",
  "context",
  "flexModuleModelStore",
  "flightsStore",
  "compositionStore"
)(
  observer((props: ModuleMapProps) => {
    if (props.templateComponent.config.view === "dynamic") {
      return <DynamicMaps {...props} />;
    }
    if (props.templateComponent.config.view === "no-pins-map") {
      return <EGMaps {...props} noPins />;
    }
    if (props.templateComponent.config.view === "always-enabled-link") {
      return <EGMaps {...props} alwaysEnabledLink />;
    }
    return <EGMaps {...props} />;
  })
);

export default MapsViews;
