// External libraries
import * as React from "react";
import { observer } from "mobx-react";
import { useDialog } from "@shared-ui/dialog-context";
import { isVariantEnabled } from "components/utility/experiment";
import { useLocalization } from "@shared-ui/localization-context";
import { withStores } from "stores";

// UITK
import { EGDSLink } from "@egds/react-core/link";
import { EGDSCard, EGDSCardContentSection } from "@egds/react-core/cards";
import { EGDSFigure, EGDSFigureAspectRatioType, EGDSImage } from "@egds/react-core/images";
import { EGDSText } from "@egds/react-core/text";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { EGDSSheetTransition } from "@egds/react-core/sheet";

// Types
import { ModuleMapProps } from "./typings";
import { ClickAction } from "./MapsEnums";
import { MapsFlexModuleResult } from "typings/microserviceModels/maps-flex-module";

// Components
import { useFullscreenOpen } from "./hooks";
import { MapContext } from "./MapContext";
import { getFmId } from "src/stores/ExperienceTemplateStore";
import { convertAspectRatioToType } from "src/components/utility/DynamicMapUtils";

const EGMapsModule = React.lazy(() => import(/* webpackChunkName: "module-map" */ "./EGMapsModule"));

/**
 * Wrapper Map Component. Here we inject the stores and is rendered the call to action button
 * and is rendered the Main Map Component.
 * @param {MapsProps} props
 */
export const EGMaps = withStores(
  "analytics",
  "hotels",
  "staticMap",
  "flexModuleModelStore"
)(
  observer((props: ModuleMapProps) => {
    const {
      templateComponent,
      context,
      hotels: hotelsStore,
      staticMap,
      flexModuleModelStore,
      noPins,
      alwaysEnabledLink,
    } = props;
    const { formatText } = useLocalization();
    const [isClient, setIsClient] = React.useState(false);

    if (!templateComponent) {
      return null;
    }
    const {
      metadata: { id },
      config: { fmTitleId, maxHeight },
    } = templateComponent;
    const fmId = getFmId(templateComponent);
    const [isDialogOpen, dialogActions, MapDialogComponent] = useDialog(`open-eg-${id}`);
    const { sendUserToSRP, toggleShowFullscreenMap } = useFullscreenOpen(props, isDialogOpen, dialogActions);

    React.useEffect(() => {
      setIsClient(true);
    }, []);

    const model = flexModuleModelStore.getModel(id) as MapsFlexModuleResult | null;

    if (!model) {
      return null;
    }

    const location = model.location ?? props.context.searchContext.location;
    const shouldOpenMapInSRP = model.clickAction === ClickAction.SEND_TO_SRP;
    const mapCTAClickAction = shouldOpenMapInSRP ? sendUserToSRP : toggleShowFullscreenMap;

    const ctaText = isVariantEnabled(context, "Blossom_SRP_Lite_V2")
      ? formatText("maps.callToActionButtonViewInAMap")
      : formatText("maps.callToActionButtonShowMap");

    const aspectRatio = convertAspectRatioToType(model.aspectRatio, EGDSFigureAspectRatioType.R4_1);

    const disabled = !alwaysEnabledLink && !hotelsStore.allHotelsOnMap.length && !noPins;

    return (
      <EGDSSpacing padding={{ block: "three" }}>
        <div className="Maps" id={id} data-fm={fmId} data-fm-title-id={fmTitleId} data-testid="eg-maps">
          <EGDSLink
            onClick={mapCTAClickAction}
            disabled={disabled}
            className="uitk-map-call-to-action uitk-layout-flex-align-items-stretch uitk-layout-flex-flex-direction-column"
            inline
          >
            <button>
              <EGDSCard border>
                <EGDSFigure
                  ratio={aspectRatio}
                  style={maxHeight !== undefined ? { maxHeight: maxHeight, paddingBottom: "0" } : undefined}
                >
                  <EGDSImage
                    alt={formatText("maps.hotel.multipleHotels.a11yLabel", location?.name)}
                    src={noPins ? staticMap.signedUrlNoPins : staticMap.signedUrl}
                    placeholderImage
                    lazyLoading="lazy"
                  />
                </EGDSFigure>
                <EGDSCardContentSection>
                  <EGDSText size={300} align="center">
                    {ctaText}
                  </EGDSText>
                </EGDSCardContentSection>
              </EGDSCard>
            </button>
          </EGDSLink>
          <MapContext.Provider
            value={{
              toggleShowFullscreenMap,
              sendUserToSRP,
              shouldOpenMapInSRP,
            }}
          >
            <EGDSSheetTransition isVisible={isDialogOpen}>
              <MapDialogComponent>
                {isClient && (
                  <React.Suspense fallback={<div />}>
                    <EGMapsModule {...props} />
                  </React.Suspense>
                )}
              </MapDialogComponent>
            </EGDSSheetTransition>
          </MapContext.Provider>
        </div>
      </EGDSSpacing>
    );
  })
);

EGMaps.displayName = "EGMaps";

export default EGMaps;
