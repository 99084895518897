// External libraries
import * as React from "react";
import { observer } from "mobx-react";
import { useDialog } from "@shared-ui/dialog-context";
import { EGDSFigure, EGDSFigureAspectRatioType, EGDSImage } from "@egds/react-core/images";
import { classNames } from "@egds/react-core/utils";
import { EGDSLoader } from "@egds/react-core/loader";
import { withStores } from "stores";

// Components
import { ModuleMapProps } from "../typings";
import { useFullscreenOpen } from "../hooks";
import { MapContext } from "../MapContext";
import { MapsFlexModuleResult } from "typings/microserviceModels/maps-flex-module";
import { getFmId } from "src/stores/ExperienceTemplateStore";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { EGDSLayoutFlex } from "@egds/react-core/layout-flex";
import { convertAspectRatioToType } from "src/components/utility/DynamicMapUtils";

const ModuleMap = React.lazy(() => import(/* webpackChunkName: "module-map" */ "../ModuleMap"));

const isInViewport = (element: HTMLDivElement): boolean => {
  const distance = element.getBoundingClientRect();
  if (!distance) {
    return false;
  }

  return (
    distance.bottom > (window.innerHeight || document.documentElement.clientHeight) &&
    distance.top < (window.innerHeight || document.documentElement.clientHeight)
  );
};

export const DynamicMaps = withStores(
  "staticMap",
  "context"
)(
  observer((props: ModuleMapProps) => {
    const { staticMap, templateComponent, context, flexModuleModelStore } = props;
    if (!templateComponent) {
      return null;
    }
    const {
      metadata: { id },
      config: { fmTitleId },
    } = templateComponent;
    const fmId = getFmId(templateComponent);
    const [isDialogOpen, dialogActions] = useDialog(`open-${id}`);
    const { sendUserToSRP, toggleShowFullscreenMap } = useFullscreenOpen(props, isDialogOpen, dialogActions);
    const model = flexModuleModelStore.getModel(id) as MapsFlexModuleResult | null;
    if (!model) {
      return null;
    }
    const shouldOpenMapInSRP = false;
    const countryCode = context?.defaultCountry || "";
    const elemRef = React.useRef<HTMLDivElement | null>(null);
    const placeHolderAltText =
      (context &&
        context.searchContext &&
        context.searchContext.location &&
        context.searchContext.location.extendedName) ||
      "";

    const aspectRatio = convertAspectRatioToType(model.aspectRatio, EGDSFigureAspectRatioType.R21_9);

    if (typeof window === "undefined") {
      staticMap.generateGooglePlaceHolderMapImage(context, countryCode);
    }

    const [isLoaderVisible, showLoader] = React.useState(true);

    const [isBottomSheetSticky, setBottomSheetPosition] = React.useState(true);

    const [isClient, setIsClient] = React.useState(false);

    React.useEffect(() => {
      setIsClient(true);
    }, []);

    // Handler anchoring bottom sheet to the bottom of the window
    React.useEffect(() => {
      const viewportSizeCheck = () => {
        setBottomSheetPosition(isInViewport(elemRef.current as HTMLDivElement));
      };
      window.addEventListener("scroll", viewportSizeCheck);

      return () => window.removeEventListener("scroll", viewportSizeCheck);
    }, []);

    const toggleLoader = () => {
      showLoader(!isLoaderVisible);
    };

    return (
      <EGDSSpacing padding={{ block: "three" }}>
        <div className="Maps" id={id} data-fm={fmId} data-fm-title-id={fmTitleId}>
          <EGDSFigure ratio={aspectRatio}>
            {staticMap && staticMap.signedPlaceHolderUrl && (
              <EGDSImage alt={placeHolderAltText} src={staticMap.signedPlaceHolderUrl} data-testid="placeholder-map" />
            )}
            {isLoaderVisible && <EGDSLoader className="dynamicMapLoader" />}
            <EGDSLayoutFlex domRef={elemRef} className="dynamicMap" data-testid="dynamic-map">
              <MapContext.Provider
                value={{
                  shouldOpenMapInSRP,
                  sendUserToSRP,
                  toggleShowFullscreenMap,
                }}
              >
                {isClient && (
                  <React.Suspense fallback={<EGDSLoader className="dynamicMapLoader" />}>
                    <ModuleMap
                      {...props}
                      classNames={classNames(
                        "uitk-dialog-content uitk-dynamic-map",
                        isBottomSheetSticky && "setStickyBottomSheet"
                      )}
                      onMapMounted={toggleLoader}
                      isDynamic
                    />
                  </React.Suspense>
                )}
              </MapContext.Provider>
            </EGDSLayoutFlex>
          </EGDSFigure>
        </div>
      </EGDSSpacing>
    );
  })
);

DynamicMaps.displayName = "DynamicMaps";

export default DynamicMaps;
